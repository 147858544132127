/*******************************************
    ログインページ
*******************************************/
@use "../global" as g;

#login {

    .sec{
        $this: '.sec';
        padding: 40px;
    
        @include g.mq('md') {
            padding: 120px 0;
        }
    }

    #wpmem_login,#wpmem_reg{
        padding: 80px;
        margin: 0 auto;
        background-color: g.color('bg');
        
        @include g.mq('sm') {
            width: 100%;
            padding: 40px;
        }
    }

    #wpmem_login legend, #wpmem_reg legend {
        margin-bottom: 15px;
        
        @include g.mq('md') {
            margin-bottom: 30px;
        }
    }

    #wpmem_login .buttons, #wpmem_reg .buttons {
        cursor: pointer;
        background-color: g.color('key');
        color: #FFF;
        padding: 10px 20px;
    }

    #wpmem_msg, .wpmem_msg{
        margin: 15px auto;
    }
}

