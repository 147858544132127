/*------------------------------------------
    モーダル
------------------------------------------*/
@use "../global" as g;

.modal {
    /**
 * アニメーション
 * ---------------------------------------------------------------------------------------
 */
.popup-reference-fade-enter-active,
.popup-reference-fade-leave-active {
  transition: opacity 0.5s;
}
.popup-reference-fade-enter-from,
.popup-reference-fade-leave-to {
  opacity: 0;
}
.popup-reference-fade-enter-to,
.popup-reference-fade-leave-from {
  opacity: 1;
}

/**
 * 全体枠
 * ---------------------------------------------------------------------------------------
 */
.popup-reference {
  display: inline-block;
}
/**
 * 開くボタン
 * ---------------------------------------------------------------------------------------
 */
.open-button {
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;

  @include g.mq('lg') {
    margin-top: g.clamp_size(15, 25);
    margin-bottom: g.clamp_size(15, 25);
  }

  &.is-read {
    opacity: 0.3;
  }

  img {
    display: block;
    margin-bottom: 2px;
    width: 63px;

    @include g.mq('lg') {
      width: g.clamp_size(63, 122);
    }
  }
}

.open-button__label {
  display: block;
  color: g.color('reference');
  font-weight: bold;
  font-size: 1.1rem;

  @include g.mq('md') {
    font-size: 10px;
  }

  @include g.mq('lg') {
    font-size: g.clamp_size(10, 21);
  }
}

/**
 * ポップアップ
 * ---------------------------------------------------------------------------------------
 */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 4%;
  overflow-y: scroll;
  pointer-events: none;
  z-index: 1000;

  @include g.mq('lg') {
    padding: g.clamp_size(30, 100) g.clamp_size(30, 140);
  }

  &.is-open {
    pointer-events: auto;
  }
}

/**
 * 背景
 * ---------------------------------------------------------------------------------------
 */
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

/**
 * 内容枠
 * ---------------------------------------------------------------------------------------
 */
.main {
  position: relative;
  overflow: visible;
  width: auto;
  max-width: 90%;
  padding: 8%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  color: g.color('base');
  background-color: #fff;
  border-radius: 20px;

  @include g.mq('md') {
    padding: 40px;
  }
}

/**
 * 閉じるボタン
 * ---------------------------------------------------------------------------------------
 */
.close-button {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -23px;
  right: -23px;
  border-radius: 50%;
  background: g.color('base');
   
  &::before,&::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 21px;
    background: #fff;
  }
   
  &::before {
    transform: translate(-50%,-50%) rotate(45deg);
  }
   
  &::after {
    transform: translate(-50%,-50%) rotate(-45deg);
  }

  @include g.mq('lg') {
    width: 70px;
    height: 70px;
    top: -33px;
    right: -33px;
  }
}

/**
 * タイトル
 * ---------------------------------------------------------------------------------------
 */
.title {
  margin-bottom: 15px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: g.color('reference');

  @include g.mq('lg') {
    margin-bottom: g.clamp_size(15, 30);
    font-size: g.clamp_size(16, 38);
  }

  img {
    width: 93px;

    @include g.mq('lg') {
      width: g.clamp_size(93, 18);
    }
  }
}
}