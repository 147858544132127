/*******************************************
    SDGs
*******************************************/
@use "../global" as g;

#sdgs {

    /*------------------------------------------
    耐火物産業SDGs宣言
------------------------------------------*/

.about {
    padding: 46px 0 70px;

    @include g.mq('md') {
        padding: 148px 0 107px;
    }

    &__wrap {
        @include g.mq('md') {
            display: flex;
            justify-content: flex-start;
            gap: 12.5%;
        }
    }

    &__txt {
        margin-top: 27px;

        @include g.mq('md') {
            margin: 0;
            max-width: 630px;
        }

        p {
            &:not(:first-of-type) {
                margin-top: .9em;
            }
        }
    }

    &__img {
        margin-top: 27px;

        @include g.mq('md') {
        margin-top: 60px;
        }
    }
}

/*------------------------------------------
    sec
------------------------------------------*/

.first {
    $this: '.sec';
    padding: 46px 0 80px;

    @include g.mq('md') {
        padding: 148px 0 120px;
    }
}

    .sec {
        $this: '.sec';
        padding: 72px 0;

        @include g.mq('md') {
            padding: 120px 0;
        }

        &:nth-of-type(2n) {
            color: #fff;
            background: g.color('bg-dark');

            #{$this}__item {
                &:not(:last-child) {
                    @include g.mq('md') {
                    margin-right: g.clamp_size(20,80,768,1200);
                    padding-right: g.clamp_size(20,80,768,1200);
                    }
                }
            }

            #{$this}__link {
                background: rgba(255,255,255,.05);
            }
        }

        &:last-child {
            padding-bottom: 80px;

            @include g.mq('md') {
                padding-bottom: 140px;
            }
        }

        &__list {
            margin-top: 36px;

            @include g.mq('md') {
                margin-top: 56px;
            display: flex;
            align-items: stretch;
            }
        }

        &__item {
            @include g.mq("sm") {
                margin-bottom: 28px;
                padding-bottom: 28px;
                border-bottom: 1px dotted g.color('border-thin');
                }

                @include g.mq('md') {
                    width: 50%;
                    }

            &:not(:last-child) {
                @include g.mq('md') {
                margin-right: g.clamp_size(20,40,768,1200);
                padding-right: g.clamp_size(20,40,768,1200);
                border-right: 1px dotted g.color('border-thin');
                }
            }

            p {
                padding: 0 10px;
            }
        }

        &__link {
            width: 100%;
            background: g.color('bg');
            text-align: left;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: .4em;
            line-height: 1.4;
            margin-top: 18px;

            @include g.mq('md') {
                margin-top: 34px;
            }

            &:not(:first-of-type) {
                margin-top: 12px;
            }
            
            &::after {
                content: "";
                display: block;
                padding-top: .8em;
                width: 1em;
                height: 1em;
                background: url(/assets/img/common/icon_blank_key.svg) no-repeat center center / contain;
            }
        }
        
        &__img-list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
            gap: 14px;

            @include g.mq('md') {
            margin-top: 60px;
            gap: 20px;
            grid-template-columns: repeat(6, 1fr);
            }
        }

        &__img {

        }
    }

}