/*******************************************
    協会組織
*******************************************/
@use "../global" as g;

#director {

/*------------------------------------------
    sec
------------------------------------------*/

    .sec {
        $this: '.sec';
        padding: 46px 0 80px;

        @include g.mq('md') {
            padding: 148px 0 120px;
        }

        &__wrap {
            @include g.mq('md') {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 10%;
                position: relative;
            }
        }

        &__list {
            margin-top: 27px;
            line-height: 1.8;

            @include g.mq('md') {
                flex: 1;
                margin: 0;
                max-width: 560px;
                min-width: 355px;
            }
        }

        &__item {
            margin-top: 60px;

            @include g.mq('md') {
                margin-top: 100px;
            }

            &:first-child {
                @include g.mq('md') {
                    margin-top: 0;
                }
            }

            &.is-top {
                #{$this}__cont-txt {
                    span {
                        margin-left: -.5em;
                    }
                }
            }
        }

        &__tit-area {
            word-break: break-all;

            @include g.mq('md') {
                position:sticky;
                top: 200px;
            }
        }

        &__tit {
            color: g.color('key');
            font-size: 1.8rem;
            margin-bottom: 24px;
        
            @include g.mq('md') {
                font-size: 2.0rem;
            }
        }

        &__cont-list {
            padding: 16px 0;
            border-bottom: 1px dotted g.color('border');

            @include g.mq('md') {
                display: flex;
            }

            &:first-of-type {
                border-top: 1px dotted g.color('border');
            }
        }

        &__cont-tit {
            width: 6em;
            font-weight: 600;
            margin-bottom: .5em;

            @include g.mq('md') {
                width: 120px;
            }
        }

        &__cont-txt {

            span {
                display: block;
                font-size: 1.3rem;
            }
        }

        &__add-item {
        }

        &__tit-s {
            margin-top: 1em;
            font-weight: normal;
        }

    }

}