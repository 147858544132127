@use "../global" as g;

/*------------------------------------------
	tit
------------------------------------------*/

.tit-en {
	font-size: 4.6rem;
	font-family: g.font('en');
	font-weight: 600;
	letter-spacing: .02em;
	margin-bottom: 5px;
	line-height: 1;
	color: g.color('key');
	text-align: left;

	@include g.mq('md') {
		font-size: 6.0rem;
	}

	span {
		color: g.color('base');
	}

	&.is-white {
		span {
			color: #fff;
		}
	}

	&.is-bg-key {
		color: #fff;

		span {
			color: g.color('base');
		}
	}

	&.is-bg-dark {
		span {
			color: g.color('tit-white');
		}
	}
}

.tit-ja {
	font-size: 1.8rem;

	@include g.mq('md') {
		font-size: 2.0rem;
	}
}

