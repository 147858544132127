@use "../global" as g;

/*------------------------------------------
	グロナビ
------------------------------------------*/
.gnav {
	position: relative;
	z-index: map-get(g.$z-index, 'header');

	@include g.mq('lg-max') {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: map-get(g.$headerH, 'sm');
		background-color: #fff;
		visibility: hidden;
		opacity: 0;
		transform: translateX(-100%);
		transition: 0.4s;
	
		&.is-active {
			visibility: visible;
			opacity: 1;
			transform: translateX(0);
		}
	}

	&__close {
		@include g.close-icon(30px, 2px, g.color('base'));
		position: absolute;
		top: 23px;
		right: g.get-inner-side(g.$inner-width-sp);

		@include g.mq('lg') {
			display: none;
		}
	}


	&__member-list {
		position: absolute;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		font-size: 1.3rem;
		padding: 30px;

		@include g.mq('lg') {
			position: relative;
			bottom: auto;
			justify-content: flex-end;
			padding: 0 0 13px 0;
		}
	}

	&__member-item {
		padding: 5px 10px;

		@include g.mq('lg') {
			padding: 0 .7em;
		}

		&.is-member-menu {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 6px;
			width: 100%;

			@include g.mq('lg') {
				margin-left: 1em;
				padding-left: 1.8em;
				border-left: 1px dotted g.color('border');
				width: auto;
				line-height: 1;
			}

			&::before {
				content: "";
				display: block;
				width: 11px;
				height: 12.5px;
				background: url(/assets/img/common/icon_member.svg) no-repeat center center / contain;
			}
		}
	}

	&__list {
		padding: 30px;

		@include g.mq('lg') {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 1.7em;
			width: auto;
			padding: 0;
		}
	}

	&__item {
		@include g.mq('lg-max') {
			border-top: 1px dotted g.color('border-thin');
		}

		a {
			display: block;
			font-size: 1.5rem;
			letter-spacing: 0;
			padding: 14px 0;

			@include g.mq('lg') {
				font-size: 1.6rem;
				padding: 0;
			}
		}

		&.is-contact {
			padding-top: 30px;
			margin-left: .5em;

			@include g.mq('lg') {
				padding: 0;
			}

			a {
				background: g.color('key');
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .16em;
				text-align: center;
				font-family: g.font('en');

				@include g.mq('lg') {
					padding: 13.5px 36px;
				}
			}
		}
	}

}