/*******************************************
    トップページ
*******************************************/
@use "../global" as g;

#frontpage {


    .frontpage {
        &__btn-wrap {
            @include g.mq('sm') {
                margin-top: 25px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

/*------------------------------------------
    mv
------------------------------------------*/

.mv {
    margin: 90px auto;
    width: 100%;
    overflow-x: hidden;

    @include g.mq('md') {
        margin: 145px auto 0;
    }

    &__cont {
        position: relative;
        z-index: 1;

        @include g.mq('md') {
            margin: 0 auto;
            width: min(92%, 1010px);
        }

        span {
            display: block;
        }
    }

    &__tit-en {
        font-size: 9vw;
        line-height: 1.14;
        letter-spacing: .02em;
        font-family: g.font('en');
        margin-bottom: 1.2vw;

        @include g.mq('md') {
            font-size: 7.8rem;
            margin-bottom: 11px;
        }

        span {
            &:first-child {
                color: g.color('key');
            }
        }
    }

    &__tit-ja {
        font-size: max(1.0rem, 2.4vw);
        line-height: 1.94;
        letter-spacing: .02em;
        padding-left: 1vw;

        @include g.mq('md') {
            font-size: 1.96rem;
            padding-left: 7px;
        }
    }

    &__anime {
        width: 0;
        margin: 1px;
        white-space: nowrap;
        overflow: hidden;
        animation: anime-mv-txt 3s forwards linear;
    }
    
    @keyframes anime-mv-txt {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }

    &__scroll {
        position: absolute;
        bottom: -27vw;
        left: -50px;
        font-size: 1rem;
        color: g.color('key');
        transform: rotate(90deg);
        width: 114px;
        height: 20px;
        opacity: 0;
        animation: anime-scroll-fade .3s forwards ease;
        animation-delay: 5.2s;
        
        
        @keyframes anime-scroll-fade {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 100%;
            }
        }
        
        @include g.mq('md') {
            font-size: 1.18rem;
            left: 50%;
            margin-left: -56%;
            top: auto;
            bottom: -160px;
        }
        @include g.mq('lg') {
            margin-left: calc(g.clamp_size(550,646,1200,1600) * -1);
        }
    
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background: g.color('key');
        }

        &::after {
            content: "";
            position: absolute;
            z-index: 2;
            top: -2px;
            left: 0;
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background: g.color('key');
            opacity: 0;
            animation: anime-scroll 2s infinite forwards ease;
        }
        
        @keyframes anime-scroll {
            0% {
                opacity: 0;
                left: 0;
            }
            10% {
                opacity: 100%;
            }
            100% {
                left: 114px;
            }
        }
    }

    &__img-area {
        position: relative;
        margin-top: -11vw;

        @include g.mq('md') {
            margin-top: calc(g.clamp_size(0,130,768,1600) * -1);
        }

    }

    &__anime-img {
        position: absolute;
        top: 0;
        left: -10%;
        width: 110%;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,1) 10%);
        animation: anime-mv-img 2.3s forwards ease-in;
    }
    
    @keyframes anime-mv-img {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(110%);
        }
    }

    &__img {
        display: block;
    }

}


/*------------------------------------------
    news
------------------------------------------*/

.news {
    margin-top: -28vw;

    @include g.mq('md') {
        margin-top: -5.3vw; //-85px
    }

    &__wrap {
        @include g.mq('md') {
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 30px;
        }
    }

    &__tit {
        @include g.mq('md') {
            width: 260px;
        }
    }

    &__list {
        margin-top: 40px;

        @include g.mq('md') {
            margin: 0;
            flex: 1;
        }
    }

    &__item {
        padding-bottom: 30px;
        border-bottom: 1px dotted g.color('border');

        @include g.mq('md') {
            display: flex;
            gap: 27px;
        }

        &:not(:first-child) {
            padding-top: 30px;
        }

        time {
            margin-bottom: 10px;
            color: g.color('base-thin');

            @include g.mq('md') {
                margin: 0;
                min-width: 130px;
            }
        }

        a {
            display: block;

            @include g.mq('md') {
                flex: 1;
            }
            
            &::after {
                content: "";
                display: inline-block;
                padding-top: .8em;
                width: 1em;
                height: 1em;
                background: url(/assets/img/common/icon_blank_key.svg) no-repeat center center / contain;
                vertical-align: text-top;
                margin-left: 10px;
            }
        }

        p {
            line-height: 2.06;
        }
    }

    &__btn {
        @include g.mq('md') {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
        }
    }

}

/*------------------------------------------
    about
------------------------------------------*/

.about {
    margin-top: 120px;
    position: relative;
    padding-bottom: 10%;

    @include g.mq('md') {
        margin-top: 105px;
        padding: 0;
    }

    &__wrap {
        @include g.mq('md') {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 9.15vw;
        }
    }

    &__cont {
        @include g.mq('md') {
            margin: 60px auto 0 0;
            width: 388px;
        }
    }

    &__txt {
        margin-top: 27px;
        max-width: 480px;
    }

    &__btn {
        @include g.mq('md') {
            margin-top: 27px;
        }
    }

    &__img {
        margin-top: 60px;
        margin-left: auto;
        width: min(80%, 500px);
        height: 500px;

        @include g.mq('md') {
            margin-top: 71px;
            margin-left: 0;
            width: min(529px, 33.06vw);
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__bg {
        display: block;
        position: absolute;
        z-index: -1;
        background: g.color('key');
        left: 0;
        bottom: 0;
        width: 50%;
        height: min(50%, 400px);

        @include g.mq('md') {
            left: 0;
            top: 0;
            width: 34.37vw;
            height: 306px;
        }
    }

}

/*------------------------------------------
    what
------------------------------------------*/

.what {
    margin-top: 100px;
    padding: 90px 0 0;
    background: g.color('bg-dark');
    color: #fff;

    @include g.mq('md') {
        margin-top: 140px;
        padding: 0;
    }

    &__wrap {
        @include g.mq('md') {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4%;
        }

        @include g.mq('lg') {
            gap: 10.93%;
        }
    }

    &__cont {
        @include g.mq('md') {
            flex: 1;
            width: auto;
            padding-left: 4%;
        }
    }

    &__txt-area {
        @include g.mq('md') {
            width: min(600px, 100%);
            padding-top: 40px;
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__txt {
        margin-top: 27px;

        @include g.mq('md') {
            margin-top: 18px;
        }
    }

    &__btn {
        @include g.mq('md') {
            margin-top: 20px;
        }
    }

    &__img {
        margin-top: 60px;

        @include g.mq('md') {
            margin: 0;
            width: 38.8%;
            height: 645px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/*------------------------------------------
    sdgs
------------------------------------------*/

.sdgs {
    padding-top: 80px;
    overflow: hidden;

    @include g.mq('md') {
        padding-top: 160px;
        position: relative;
        height: 684px;
        width: 100%;
    }

    &__cont {
        @include g.mq('md') {
            width: 388px;
            margin-left: auto;
        }
        @include g.mq('lg') {
            margin-right: 45px;
        }
    }

    &__txt {
        margin-top: 25px;
    }

    &__btn {
        @include g.mq('md') {
            margin-top: 20px;
        }
    }

    &__bg {
        position: relative;
        width: 146%;
        left: 4%;
        margin-top: 6%;

        @include g.mq('md') {
            position: absolute;
            margin-top: 0;
            left: -19.7%;
            bottom: 0;
            width: 75.68vw;
        }
    }

    &__anime {
        animation-name: anime-sdgs;
        animation-duration: 1.3s;
        animation-fill-mode: forwards;
        opacity: 0;
    }

    @keyframes anime-sdgs {
        from {
            opacity: 0;
            transform: scale(1.5) rotate(8deg);
        }
        to {
            opacity: 1;
            transform: scale(1) rotate(0);
        }
    }
}

/*------------------------------------------
    for-member
------------------------------------------*/

.for-member {
    padding: 80px 0;

    @include g.mq('md') {
        padding: 120px 0 90px;
    }

    &__wrap {
        @include g.mq('md') {
            display: flex;
            justify-content: space-between;
            gap: g.clamp_size(20,167,768,1200);
        }
    }

    &__cont {

        @include g.mq('md') {
            width: 400px;
        }
    }

    &__attention {
        display: block;
        width: 100%;
        border: 1px solid g.color('key');
        color: g.color('key');
        text-align: center;
        padding: 10px;
        margin-top: 30px;

        @include g.mq('md') {
            margin-top: 46px;
        }
    }

    &__txt {
        margin-top: 20px;
    }

    &__btn {
        @include g.mq('md') {
            margin-top: 23px;
        }
    }

    &__list {
        margin-top: 60px;

        @include g.mq('md') {
            flex: 1;
            min-width: 320px;
            margin: 0;
        }
    }

    &__item {
        position: relative;
        
        &:not(:first-child) {
            padding-top: 23px;
        }
        
        &:not(:last-child) {
            padding-bottom: 27px;
            border-bottom: 1px dotted g.color('border-dark');
        }

        img {
            width: min(45%, 200px);
            max-height: 100%;
            object-fit: cover;
            border-radius: 6px;
        }

        a {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 40px;
            height: 90px;

            @include g.mq('md') {
                height: 122px;
            }
        }

        p {
            flex: 1;
            display: flex;
            align-items: center;
        }
    }

    &__icon {
        position: relative;
        z-index: 0;
        display: block;
        width: 24px;
        height: 100%;
        border-radius: 0 3px 3px 0;
        background: g.color('key');

        span {
            position: absolute;
            z-index: 1;
            right: 10.5px;
            top: calc(50% - 5.5px);
            @include g.arrow("right", .9rem, 2px, #fff, true);
        }
    }
}

}

