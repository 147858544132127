// ブレイクポイント
$breakpoints: (
    'xs': 'screen and (max-width: 375px)',
    'sm': 'screen and (max-width: 767px)',
    'md': 'screen and (min-width: 768px)',
    'lg': 'screen and (min-width: 1080px)',
    'lg-max': 'screen and (max-width: 1079px)',
    'xl': 'screen and (min-width: 1200px)',
    'xl-max': 'screen and (max-width: 1190px)',
)!default;

// inner-width
$inner-width: 1200px;
$inner-width-small: 900px;
$inner-width-sp: 92%;

// フォント
// 出力例) font('base');
$font: (
    'base': ("Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif),
    'en': ("Montserrat", Arial, sans-serif),
);

// 色管理
// 出力例) color('base');
// 多次元mapの出力例) color('cat', 'event', 'performance');
$color: (
	'base': #333,
    'base-thin': #4d4d4d,
    'base-thin02': #757575,
    'key': #c1272d,
    'border': #808080,
    'border-thin': #d8d8d8,
    'border-dark': #4d4d4d,
    'bg': #f2f2f2,
    'bg-mv': #f8f8f8,
    'bg-dark': #3b2c27,
    'btn-border': #999999,
    'tit-white': #e6e6e6,
);

// 重ね順管理
$z-index: (
    'header': 1000,
    'gotop': 999,
);

//ヘッダーの高さ
$headerH: (
    'sm': 70px,
    'md': 127px
);