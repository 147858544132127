@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	ボタン
------------------------------------------*/

.btn {
	$this: '.btn';
	position: relative;
	z-index: 0;
	display: flex;
	align-items: center;
	gap: 26px;
	font-size: 1.4rem;
	font-family: g.font('en');
	color: g.color('key');
	letter-spacing: .16em;

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: calc(50% - .5px);
		left: 30px;
		height: 1px;
		width: 44px;
		background: g.color('key');
	}

	&::after {
		content: "";
		position: absolute;
		z-index: 2;
		top: calc(50% - 2px);
		left: 47px;
		height: 4px;
		width: 4px;
		border-radius: 50%;
		background: g.color('key');
		transition: .3s ease;
	}

	span {
		width: 56px;
		height: 56px;
		border-radius: 50%;
		background: g.color('bg');
		display: block;
	}

	&:hover {
		&::after {
			left: calc(30px + 44px);
		}
	}

	// 暗い背景の時線のみ
	&.is-bg-dark {
		span {
			background: none;
			border: 1px solid g.color('btn-border');
		}
	}
}