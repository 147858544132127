/*------------------------------------------
    アコーディオン
------------------------------------------*/
@use "../global" as g;

.aco {
	$this: '.aco';

    &__head {
        margin-top: 20px;
        text-align: center;

        a {
            position: relative;
            
            &::after {
                content: '';
                display: inline-block;
                margin-left: 15px;
                margin-bottom: 3px;
                @include g.arrow('bottom', 8px, 2px);
            }
        }
    }
    
    &__body-wrapper {
        overflow: hidden;
        transition: 0.4s;
        
        &.v-enter-from, &.v-leave-to {
            opacity: 0;
        }
    }

    &.is-active{
        #{$this}__head{
                a{
                    &::after{
                    margin-bottom: -3px;
                    transform: rotate(45deg);
                }
            }
        }
    }
}