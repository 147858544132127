/*------------------------------------------
    パンくず
------------------------------------------*/
@use "../global" as g;

.breadcrumb {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-top: 23px;
    font-size: 1.2rem;
    letter-spacing: .02em;
    max-width: 92%;

    @include g.mq('md') {
        justify-content: flex-start;
        font-size: 1.4rem;
    }

    &__item {
        &:not(:last-of-type)::after {
            content: ">";
            color: g.color('key');
            padding: 0 .5em;
        }
    }
}