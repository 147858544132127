/*******************************************
    協会規約
*******************************************/
@use "../global" as g;

#agreement {

    /*------------------------------------------
    sec
------------------------------------------*/

    .sec {
        padding: 46px 0 80px;

        @include g.mq('md') {
            padding: 148px 0 120px;
        }

        &__sec {
            margin: 4em 0;

            @include g.mq('md') {
                margin: 56px 0;
            }

            &:first-of-type {
                margin-top: 27px;

                @include g.mq('md') {
                    margin-top: 56px;
                }
            }
        }

        &__item {
            display: grid;
            grid-template-columns: 3.5em 1fr;
            gap: 0 1em;
            margin-bottom: 1.5em;

            @include g.mq('md') {
                margin-bottom: 36px;
            }

            dt {
                text-align: right;

                &:first-child {
                    text-align: justify;
                    text-align-last: justify;
                }
            }
        }

        &__midashi {
            color: g.color('base-thin02');
        }

        &__tit {
            color: g.color('key');
            font-size: 1.8rem;
            margin-bottom: 12px;

            @include g.mq('md') {
                font-size: 2.0rem;
                margin-bottom: 36px;
            }
        }

        &__list {
            counter-reset: number 0;

            li {
                display: flex;
                gap: 0 0.5em;

                &::before {
                    counter-increment: number;
                    content: "(" counter(number) ")";
                }
            }
        }

        &__year {
            margin-top: 5em;

            p {
                text-align: right;
            }
        }

        &__cap {
            margin-top: 2.5em;
            font-size: 1.2rem;
            color: g.color('base-thin02');
        }

    }

}