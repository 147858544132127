/*******************************************
    協会会員
*******************************************/
@use "../global" as g;

#member {

/*------------------------------------------
    sec
------------------------------------------*/

.sec {
    $this: '.sec';
    padding: 46px 0 80px;

    @include g.mq('md') {
        padding: 148px 0 120px;
    }

    &__list {
        margin-top: 27px;
        line-height: 1.8;

        @include g.mq('md') {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px 30px;
        }        

        @include g.mq('lg') {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px dotted g.color('border');

        @include g.mq('md') {
            padding: 34px 40px;
            margin: 0;
            background: g.color('bg');
            border: none;
            border-radius: 16px;
        }

        &:first-child {
            @include g.mq("sm") {
                padding-top: 24px;
                margin-top: 27px;
                border-top: 1px dotted g.color('border');
            }
        }

        &.is-top {
            #{$this}__cont-txt {
                span {
                    margin-left: -.5em;
                }
            }
        }
    }

    &__tit {
        font-size: 1.8rem;
    
        @include g.mq('md') {
            font-size: 2.0rem;
        }

        a {
            display: flex;
            align-items: center;
            gap: .4em;
            line-height: 1.4;
            
            &::after {
                content: "";
                display: block;
                padding-top: .8em;
                width: 1em;
                height: 1em;
                background: url(/assets/img/common/icon_blank.svg) no-repeat center center / contain;
            }
        }
    }

    &__cat {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: .6em;
        margin-top: 12px;

        span {
            display: block;
            padding: .1em .6em;
            border: 1px solid g.color('key');
            color: g.color('key');
            background: #fff;
        }
    }
    
    &__add-list{
        margin-top: 10px;
    }
}
}