@charset "utf-8";

/*******************************************
	/css/style.css
*******************************************/
@use "base";
@use "module";
@use "page";

//----------- フォント -----------//

// NotoSansJP
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese');
// Adobe Garamond Pro
@import url("https://use.typekit.net/cnx3wvq.css");