@use "../global" as g;
@use "sass:math";

// お知らせ用共通スタイル
//-------------------------------------------
@mixin break-word(){
	word-wrap: break-word; /* 古いブラウザのサポート */
	overflow-wrap: break-word; /* 長い単語を折り返す */
	word-break: break-word; /* 非推奨だが、一部のブラウザで必要 */
}

@mixin news-style() {
    .sec {
        $this: '.sec';
    
        &__wrap {
            @include g.mq('md') {
                position: relative;
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                gap: 30px;
            }
        }

		&__box{
			padding: 40px 0;
		
			@include g.mq('md') {
				padding: 80px 0;
			}

			&:first-of-type{
				padding-top: 46px;
			
				@include g.mq('md') {
					padding-top: 148px;
				}
			}

			&:nth-child(even) { //偶数
				background-color: g.color('bg-dark');
				color: #FFF;

				button{
					color: #FFF;
				}
				
				time{
					color: #FFF;
				}

				.aco__head{
					a{
						&::after{
							border-color: #FFF;
						}
					}
				}
			}
		}
    
        &__tit {
            @include g.mq('md') {
                width: 260px;
            }
        }
		
		&__sub-tit {
			font-size: 3.8rem;
			font-weight: 600;
			letter-spacing: .02em;
			margin-bottom: 5px;
			line-height: 1;
			color: g.color('key');
			text-align: left;
		
			@include g.mq('md') {
				font-size: 4.6rem;
			}
		}
    
        &__list {
            margin-top: 20px;
			
			@include g.mq('md') {
				margin-top: 40px;
			}
        }
    
        &__item {
            padding: 15px 0;
            border-bottom: 1px dotted g.color('border');
			
            @include g.mq('md') {
				display: flex;
				align-items: center;
                gap: 27px;
            }
    
            time {
				color: g.color('base-thin');

				@include g.mq('sm') {
					margin-right: 10px;
					float: left;
				}
    
                @include g.mq('md') {
                    margin: 0;
                    min-width: 130px;
                }
            }
    
            a {
                display: block;
				clear: both;
    
                @include g.mq('md') {
                    flex: 1;
                }

				&::after {
					padding-top: .8em;
				}
            }
    
            p {
				clear: both;
                line-height: 2.06;
				@include break-word();
            }

			a,#btn_view{
				@include break-word();

				&::after {
					content: "";
					display: inline-block;
					width: 1em;
					height: 1em;
					background: url(/assets/img/common/icon_blank_key.svg) no-repeat center center / contain;
					vertical-align: text-top;
					margin-left: 10px;
				}
			}
        }
    
        &__btn {
            @include g.mq('md') {
                position: absolute;
                bottom: 0;
                left: 0;
                margin: 0;
            }
        }

		&__year{
			margin-top: 20px;
			display: flex;

			&-item{
				a{
					display: block;
					padding: 0 15px;
					border: 1px solid g.color('bg');
				}

				&.is-active{
					a{
						border-color: g.color('bg');
						background-color: g.color('bg');
						color: g.color('base');
					}
				}
			}
		}
    
    }

	button{
		background-color: transparent;
		cursor: pointer;
		transition: all 0.2s ease;

		&:hover{
			opacity: .7;
		}
	}

	.pdf_box{
		&.is-download{
			@include g.mq('md') {
				order: 2;
				flex: 0 0 auto;
			}
		}
		&.is-view{
			clear: both;
			text-align: left;
		}
	}

	#btn_download{
		padding: 2px 10px;
		background-color: g.color('key');
		font-size: 1.2rem;
		color: #FFF;
		vertical-align: text-bottom;
	}
}