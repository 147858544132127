/*******************************************
    耐火物とは
*******************************************/
@use "../global" as g;

#refractories {

/*------------------------------------------
    耐火物とは？
------------------------------------------*/

    .what {
        padding: 46px 0 70px;

        @include g.mq('md') {
            padding: 148px 0 107px;
        }

        &__wrap {
            @include g.mq('md') {
                display: flex;
                justify-content: flex-start;
                gap: 12.5%;
                max-width: 1022px;
            }
        }

        &__txt {
            margin-top: 27px;

            @include g.mq('md') {
                margin: 0;
            }

            p {
                &:not(:first-of-type) {
                    margin-top: .9em;
                }
            }
        }

        &__attention {
            color: g.color('key');
        }
    }

/*------------------------------------------
    主な用途
------------------------------------------*/

    .use {
        $this: '.use';
        color: #fff;
        background: g.color('key');
        padding: 70px 0 4vw;

        @include g.mq('md') {
            padding: 100px 0 130px;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 4vw;
            margin-top: 27px;

            @include g.mq('md') {
                margin-top: 30px;
                gap: 10px;
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &__item {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 232px;
            text-align: center;
            margin: 0;

            &:last-of-type {
                border: solid 1px #fff;
                padding: 20px;
                text-align: left;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            
            button {
                background: none;
                display: block;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 10px;
                margin: 0;

                &:hover {
                    #{$this}__bg {
                    &::after {
                        background: rgba(0,0,0,.4);
                    }
                }
                }
            }
        }

        &__detail-list {
            width: 100%;
        }

        &__detail-item {
            font-size: 1.4rem;
            margin: 0.3em 0;

            &::before {
                content: "・";
            }
        }
        

        &__name {
            color: #fff;
            font-size: 1.8rem;
            letter-spacing: .1em;
            line-height: 2.2;
            text-align: center;

            @include g.mq('md') {
                font-size: 2.0rem;
            }
        }

        &__bg {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(g.color('base'), 0);
                transition: .3s ease;
            }

            img {
                max-width: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__modal-txt {
                text-align: center;
                margin-top: 1em;
                font-size: 1.8rem;
                font-weight: bold;

                @include g.mq('md') {
                    font-size: 2.0rem;
                }
        }
    }

/*------------------------------------------
    耐火物製品の紹介
------------------------------------------*/

    .product {
        padding: 80px 0;

        @include g.mq('md') {
            padding: 104px 0 120px;
        }

        &__list {
            margin-top: 27px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 12px 4vw;

            @include g.mq('md') {
                margin-top: 58px;
                justify-content: flex-start;
                gap: 53px 50px;
            }
        }

        &__item {
            width: calc(50% - 2vw);

            @include g.mq('md') {
                width: 200px;
            }
        }

        &__img {
            width: 100%;

            img {
                max-width: none;
                width: 100%;
                object-fit: cover;
            }
        }

        &__name {
            margin-top: 3px;
        }
    }
      
}