@use "../global" as g;

html {
    font-size: 10px;
    overflow-y: scroll; //スクロールバーを常に表示
}

body {
    font-family: g.font('base');
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 2.125;
    -webkit-text-size-adjust: 100%;
    color: g.color('base');

    @include g.mq('md') {
        font-size: 1.6rem;
    }
}

a {
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none; //クリックした時に選択状態にならないようにする
    -moz-user-select: none; // Firefox
    -webkit-user-select: none; // Safari、Chromeなど
    
    &:hover {
        opacity: 0.7;
    }

    &[href*="tel:"] {
        @include g.mq('md') {
            pointer-events: none;
            cursor: default;
            text-decoration: none;
        }
    }
}

p {
    text-align: justify;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

// 追従ヘッダー分の余白
.main {
    padding-top: map-get(g.$headerH, 'sm');

    @include g.mq('md') {
        padding-top: map-get(g.$headerH, 'md');
    }
}

// 特定のブレイクポイントで非表示にするクラスをそれぞれ作成
// 例: .md-noneの場合ブレイクポイントがmd以上の場合は非表示
@each $prefix, $value in g.$breakpoints {
    @media #{$value} {
        .#{$prefix}-none {
            display: none!important;
        }
    }
}

// fadeInの要素が初期描画時に一瞬表示されないようにする
.wow {
    visibility: hidden;
}