/*------------------------------------------
    sub mv
------------------------------------------*/
@use "../global" as g;

.sub-mv {
    position: relative;
    overflow-x: hidden;
    padding-top: 52px;

    @include g.mq('md') {
        padding-top: 89px;
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background: g.color('bg-mv');
        border-radius: 0 0 10vw 0;

        @include g.mq('md') {
            height: 516px;
            border-radius: 0 0 169px 0;
        }
    }

    &__tit {
        font-size: 3.8rem;
        text-align: center;
        font-weight: 100;
        line-height: 1.4;

        @include g.mq('md') {
            font-size: 4.8rem;
        }
    }

    &__en {
        font-size: 1.85rem;
        font-weight: 700;
        letter-spacing: .02em;
        color: g.color('key');
        font-family: g.font('en');
        text-align: center;

        @include g.mq('md') {
            font-size: 2.16rem;
        }
    }

    &__bg {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        line-height: .8;
        letter-spacing: .02em;
        color: rgba(g.color('key'), .05);
        font-family: g.font('en');
        font-size: 18vw;
        font-weight: 700;
        white-space: nowrap;

        @include g.mq('md') {
            font-size: 10vw;
            left: .6%;
            transform: none;
        }
    }

    &__cont {
        padding-top: 40px;

        @include g.mq('md') {
            padding-top: 97px;
            display: flex;
            justify-content: space-between;
            gap: 4.3%;
        }
    }

    &__img-area {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 220px;

        @include g.mq('md') {
            width: calc(100% - 200px);
            height: 356px;
            max-width: g.clamp_size(1000, 1200, 1600, 1920);
        }
    }

    &__img {
        width: 100%;
        height: 100%;

        img {
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__img-txt {
        position: absolute;
        z-index: 1;
        top: 16%;
        left: 4%;
        width: 92%;
        line-height: 2;
        letter-spacing: .04em;
        color: #fff;
        font-size: 1.8rem;

        @include g.mq('md') {
            top: 83px;
            left: 20.2%;
            font-size: 2.0rem;
        }
    }
}
