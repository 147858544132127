/*******************************************
    お問い合わせ
*******************************************/
@use "../global" as g;

#contact {

.sec{
    $this: '.sec';
    padding: 46px 0;

    @include g.mq('md') {
        padding: 120px 0 ;
    }
}

.form{
    $this: ".form";

    &__table{
        width: 100%;
    }

    &__row{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        
        @include g.mq('md') {
            grid-template-columns: 30% 70%;
        }

        &:first-of-type{
            #{$this}__td{
                @include g.mq('md') {
                    border-top: 1px solid g.color('bg');
                }
            }
        }
    }

    &__th{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: g.color('bg');
        border-bottom: 1px solid #FFF;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 20px 10px;
        text-align: left;
        
        @include g.mq('md') {
            padding: 20px;
            font-size: 1.8rem;
        }
    }

    &__th-wrap {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        gap: 0 5px;
    }

    &__th-note {
        font-size: 1.2rem;
    }

    &__td{
        padding: 20px 10px;
        border-bottom: 1px solid g.color('bg');
        border-right: 1px solid g.color('bg');
        border-left: 1px solid g.color('bg');
        
        @include g.mq('md') {
            padding: 20px;
            border-left: none;
        }

        &-box{
            position: relative;
        }

        &-note{
            font-size: 1.2rem;
            font-weight: normal;
        }
    }

    &__btnarea{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 40px auto 0;

        @include g.mq('md') {
            margin-top: 63px;
            flex-direction: row-reverse;
        }

        form{
            width: 100%;
        }
    }
    &__btn {
        display: block;
        font-weight: 500;
        text-align: center;
        letter-spacing: .3em;
        height: 70px;
        width: 100%;
        max-width: 462px;
        box-sizing: border-box;
        background: g.color('key');
        color: #fff;
        transition: .4s;
        margin: 0 auto;
        font-size: 1.6rem;
        cursor: pointer;
        font-size: 1.6rem;
        
        @include g.mq('md') {
            height: 91px;
            font-size: 2.3rem;
        }

        &:hover{
            opacity: .7;
        }

        &.is-back{
            background: g.color('bg-thick');
        }
    }

    &__history-file{
        position: absolute;
        top: 0;
        left: 107px;
        width: 62%;
        overflow: scroll;
        background: #FFF;
        padding: 2px;
        
        @include g.mq('md') {
            left: 128px;
            width: g.clamp_size(295,543);
            padding-right: 175px;
            overflow: auto;
        }
        
        .invalid + &{
            background: rgb(255, 225, 225);
        }
    }

}

.required{
    font-size: 1.0rem;
    background-color: g.color('key');
    padding: 2px 8px;
    color: #FFF;
    border-radius: 3px;
    margin-left: 10px;
    
    @include g.mq('md') {
        font-size: 1.2rem;
    }
}

input,textarea,select{
    display: inline-block;
    width: 100%;
    border: 1px solid g.color('border-thin');
    border-radius: 3px;
    padding: 5px 10px;
    font-family: g.font('base');

    &:focus{
        border: 1px solid g.color('base');
    }
}

}