@use "../global" as g;

/*------------------------------------------
	header
------------------------------------------*/
.header {
	position: fixed;
	top: 0;
	left: 0;
	margin: auto;
	min-width: 100%;
	z-index: map-get(g.$z-index, 'header');
	background-color: #fff;
	
	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: map-get(g.$headerH, 'sm');

		@include g.mq('md') {
			height: map-get(g.$headerH, 'md');
		}
	}

	&__logo {
		width: 190px;
		max-width: calc(92% - 60px);

		@include g.mq('lg') {
			width: 326px;
			margin-top: 45px;
		}
	}
}