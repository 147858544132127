@use "../global" as g;

/*------------------------------------------
	footer
------------------------------------------*/
.footer{
	position: relative;
	z-index: 0;
	padding: 54px 0;
	font-size: 1.2rem;
	line-height: 1.857;
	letter-spacing: .1em;
	background-image: linear-gradient(122deg, #fdfdfd, #f5f5f8);

	@include g.mq('md') {
		display: flex;
		align-items: stretch;
		justify-content: flex-end;
		font-size: 1.4rem;
		padding: 0;
	}

	&__inner {
		@include g.mq('md') {
			width: auto !important; 
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	&__cont {
		@include g.mq('xl') {
			width: 590px;
			padding: 0 20px;
			padding-top: 10px;
		}

		@include g.mq('xl-max') {
			width: min(100%, 590px);
			padding: 0 4vw;
		}
	}

    &__logo {
		width: min(210px, 80%);
		margin-bottom: 18px;

		@include g.mq('md') {
			width: 326px;
			margin-bottom: 24px;
		}
	}

	&__copyright {
		letter-spacing: .06em;

		@include g.mq('sm') {
			position: absolute;
			z-index: 1;
			left: 0;
			bottom: 18px;
			font-size: 1.0rem;
			text-align: center;
			display: block;
			width: 100%;
			margin: 0;
		}

		@include g.mq('md') {
			font-size: 1.2rem;
			margin-top: 50px;
		}
	}

	&__map {
		margin-top: 48px;
		width: 100%;
		height: 300px;

		@include g.mq('md') {
			width: 52%;
			height: 463px;
			margin: 0;
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}
}