/*******************************************
    耐火物協会
*******************************************/
@use "../global" as g;

#association {

/*------------------------------------------
    message
------------------------------------------*/

    .message {
        position: relative;
        margin: 46px 0;

        @include g.mq('md') {
            margin: 148px 0 0;
        }

        &__wrap {
            margin-top: 25px;

            @include g.mq('md') {
                display: flex;
                align-items: flex-end;
                gap: 4.5vw;
            }
        }

        &__txt-wrap {
            @include g.mq('md') {
            flex: 1;
            }
        }

        &__txt-inner {
            @include g.mq('md') {
            padding-left: calc(46vw - 600px);
            margin-left: 4vw;
            }
        }

        &__txt {
            margin-bottom: 40px;

            @include g.mq('md') {
                flex: 1;
                margin-bottom: 160px;
            }

             p{
                margin-top: 1em;
             }
        }

        &__owner {

            @include g.mq('md') {  
            position: relative;
            padding: 40px  g.clamp_size(20,340,1000,1600) 120px 40px;
            }

            &::after {
                @include g.mq('md') {
                content: "";
                position: absolute;
                z-index: -1;
                display: block;
                background: g.color('bg');
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border-radius: 2vw 0 0 0;
                }
            }

            p {
                text-align: right;
            }
        }

        &__img {
            margin-bottom: 24px;

            @include g.mq('md') {
            width: min(250px, 20vw);
            }

            img {
                @include g.mq("sm") {
                    display: block;
                    margin-left: auto;
                }
            }
        }

        &__data {
            margin-top: 24px;
            color: g.color('base-thin02');
            font-size: 1.2rem;
        }

        &__name {
            font-size: 2.6rem;
            line-height: 1.4;
            font-weight: bold;
        }

        &__name-en {
            font-family: g.font('en');
            font-weight: 600;
        }

    }

/*------------------------------------------
    history
------------------------------------------*/

.history {
    padding-top: 90px;
    background: g.color('bg-dark');
    color: #fff;

    @include g.mq('md') {
        padding: 0;
    }

    &__wrap {
        @include g.mq('md') {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            gap: 4%;
        }

        @include g.mq('lg') {
            gap: 10.93%;
        }
    }

    &__cont {
        @include g.mq('md') {
            flex: 1;
            width: auto;
            padding-left: 4%;
            padding: 130px 4% 130px 0;
        }
    }

    &__txt-area {
        @include g.mq('md') {
            width: min(600px, 100%);
            padding-top: 40px;
            margin-right: auto;
            margin-left: 0;
        }
    }

    &__txt {
        margin-top: 27px;

        @include g.mq('md') {
            margin-top: 18px;
        }

        p {
            margin-top: 1em;
        }
    }

    &__img {
        margin-top: 60px;

        @include g.mq("sm") {
            height: 430px;
        }

        @include g.mq('md') {
            margin: 0;
            width: 38.8%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/*------------------------------------------
    chronology
------------------------------------------*/

.chronology {
    position: relative;
    padding: 90px 0 80px;
    background-color: g.color('bg');

    @include g.mq('md') {
        padding: 148px 0;
        background: url(/assets/img/association/bg_chronology.webp) g.color('bg') no-repeat right center / contain;
    }

    &__table {
        margin-top: 24px;

        dl {
            display: flex;
        }

        dt,dd {
            padding: .7em 0;
        }

        dt {
            position: relative;
            width: 6em;
            text-align: justify;
            text-align-last: justify;
            margin-right: 24px;
            border-right: 1px dotted g.color('border-thin');
            font-family: g.font('en');

            @include g.mq('md') {
                margin-right: 40px;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: 1;
                display: block;
                right: -3px;
                top: calc(50% - 3px);
                width: 6px;
                height: 6px;
                background: g.color('key');
                border-radius: 50%;
            }
        }

        dd {
            flex: 1;
        }
    }
}


/*------------------------------------------
    access
------------------------------------------*/

.access {
    margin: 90px 0;
    position: relative;
    padding-bottom: 10%;

    @include g.mq('md') {
        margin: 100px auto 120px;
        padding: 0;
    }

    &__wrap {
        @include g.mq('md') {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 9.15vw;
        }
    }

    &__cont {
        @include g.mq('md') {
            margin: 60px auto 0 0;
            width: 388px;
        }
    }

    &__txt {
        margin-top: 27px;
        max-width: 480px;

        dl {
            display: flex;
            gap:  g.clamp_size(20,40,768,1200);
            border-bottom: 1px dotted g.color('border-thin');
            padding: 18px 0;

            &:first-child {
                border-top: 1px dotted g.color('border-thin');
            }
        }

        dt {
            width: 4em;
        }
    }

    &__btn {
        @include g.mq('md') {
            margin-top: 27px;
        }
    }

    &__img {
        margin-top: 60px;
        margin-bottom: 60px;
        margin-left: auto;
        width: min(80%, 500px);
        height: 360px;

        @include g.mq('md') {
            margin-top: 71px;
            margin-left: 0;
            width: min(529px, 33.06vw);
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 100% 0;
        }
    }

    &__bg {
        display: block;
        position: absolute;
        z-index: -1;
        background: g.color('key');
        left: 0;
        bottom: 0;
        width: 50%;
        height: min(50%, 320px);

        @include g.mq('md') {
            left: 0;
            bottom: 0;
            width: 34.37vw;
            height: 420px;
        }
    }

}


}